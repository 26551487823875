import Button from 'components/Button'
import { Search } from 'components/Icons'
import { CustomInput, InputWrapper } from 'components/Input/Input.style'
import React from 'react'

const SearchInput = React.forwardRef((props, ref) => {
  return (
    <InputWrapper ref={ref}>
      <CustomInput {...props} />
      <Button
        style={{
          background: '#872867',
        }}
        color="search"
        {...(props.buttonProps || {})}
      >
        <Search color="white" />
      </Button>
      {props.suggestion}
    </InputWrapper>
  )
})

export default SearchInput
