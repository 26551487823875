import { DeviceDetectProvider } from 'components/DeviceDetect'
import React, { useEffect } from 'react'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { renderRoutes, routes } from 'route'
import { AuthContextProvider } from 'store/AuthContext'
import { CacheContextProvider } from 'store/CacheProvider'
import { CartContextProvider } from 'store/CartProvider'
import { ConfigContextProvider } from 'store/ConfigProvider'
import { FilterContextProvider } from 'store/FilterProvider'
import { InnerPageContextProvider } from 'store/InnerPageProvider'
import { OrdersContextProvider } from 'store/OrdersProvider'
import { SingleProductContextProvider } from 'store/SingleProductProvider'
import './App.css'
import BackToTop from './components/BackToTop/BackToTop'
import { getUrlParam } from 'consts'

function App(props) {
  let cache = props.cache

  if (!cache && typeof window != 'undefined') {
    cache = window.cache
  }

  useEffect(() => {
    if (getUrlParam('gclid')) {
      localStorage.setItem('gclid', getUrlParam('gclid'))
    }
    if (getUrlParam('fbclid')) {
      localStorage.setItem('fbclid', getUrlParam('fbclid'))
    }
    if (getUrlParam('ctclid')) {
      localStorage.setItem('ctclid', getUrlParam('ctclid'))
    }
  }, [])

  return (
    <DeviceDetectProvider>
      <CacheContextProvider cache={cache}>
        <AuthContextProvider>
          <ConfigContextProvider>
            <OrdersContextProvider>
              <SingleProductContextProvider>
                <CartContextProvider>
                  <FilterContextProvider>
                    <InnerPageContextProvider>
                      {renderRoutes(routes)}
                      <BackToTop />
                    </InnerPageContextProvider>
                  </FilterContextProvider>
                </CartContextProvider>
              </SingleProductContextProvider>
            </OrdersContextProvider>
          </ConfigContextProvider>
        </AuthContextProvider>
      </CacheContextProvider>
    </DeviceDetectProvider>
  )
}

export default App
