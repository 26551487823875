import styled from 'styled-components'


export const BackToTopButton = styled.a`
 position:fixed;
  z-index: 32;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  right: 10px;
  bottom: 75px;
  background-color:#872867;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  
  @media screen and (max-width: 559px){
    width: 54px;
    height: 54px;
    right: 5px;
    bottom: 65px;
  }
`

