import React from 'react'
import { LoaderContainer } from './Loader.style'
import RawLoader from 'react-loader-spinner'

const Loader = props => {
  const { transparent, css, notFull, fullScreen, ...rest } = props
  return (
    <LoaderContainer
      transparent={transparent}
      fullScreen={fullScreen}
      notFull={notFull}
      css={css}
    >
      <RawLoader
        type="Puff"
        color="#872867"
        height={100}
        width={100}
        timeout={100000}
        {...rest}
      />
    </LoaderContainer>
  )
}

export default Loader
