export const tokenKey = 'citrustoken'
export const signatureKey = 'citrussignature'

export const getUrlParam = param => {
  var url = new URL(window.location.href)
  var c = url.searchParams.get(param)
  return c
}

export const appendTrackingParamsToFormData = formData => {
  formData.append('ctclid', localStorage.getItem('ctclid'))
  formData.append('gclid', localStorage.getItem('gclid'))
  formData.append('fbclid', localStorage.getItem('fbclid'))
  return formData
}
