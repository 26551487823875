const theme = {
  color: {
    white: '#ffffff',
    red: '#872867',
    red2: '#872867',
    orange: '#ff6700',
    orange1: '#ffb500',
    orange2: '#ffad00',
    orangeLight: 'rgba(255,167,1,0.1)',
    blue1: '#1e90ff',
    blue2: '#02a8ff',
    blue3: '#15a6ff',
    blue4: '#00b3ff',
    blue5: '#268dff',
    lightBlue: 'rgba(30,144,255,0.11)',
    lightBlue1: 'rgba(21,166,255,0.1)',
    lightBlue2: '#f1f9fa',
    black: '#000000',
    black1: '#3c4854',
    black2: '#1a2738',
    black3: '#444c58',
    black4: '#5a6679',
    black5: '#172338',
    grey: '#919191',
    grey1: '#acb4b5',
    grey2: '#5c6772',
    greyE5: '#e5e5e5',
    greyE4: '#e4e4e4',
    greyED: '#ededed',
    grey4A: '#4a4a4a',
    lightGrey: '#e0e0e0',
    lightGrey1: '#eaeaea',
    lightGrey2: '#f2f4f8',
    lightGrey3: '#f4f6fa',
    lightGrey4: '#dfe4ea',
    mediumGrey: '#868686',
    yellow: '#ffce00',
    error: '#ff3000',
    success: '#004f00',
  },
  breakpoint: {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  font: {
    size: {
      xs: '12px',
      sm: '14px',
      md: '18px',
      lg: '26px',
      xl: '36px',
    },
    family: {
      light: 'Firago-Regular; /* font-feature-settings: "case" */',
      medium: 'Firago-Medium; /* font-feature-settings: "case" */',
      regular: 'Firago-Regular; /* font-feature-settings: "case" */',
      bold2: 'Firago-Bold; /* font-feature-settings: "case" */',
      bold: 'Firago-Regular;font-weight: bold; letter-spacing: 0.2px; /* font-feature-settings: "case" */',
    },
  },
}

export default theme
