import useIfCanSendOrder, { incrementOrder } from 'helpers/testIfCanSendOrder'
import React, { createContext, useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router'
import CartService from 'services/CartService'
import useDeviceDetect from '../components/DeviceDetect/DeviceDetect'
import useAuth from './AuthContext'
import useOrders from './OrdersProvider'
import useProduct from './SingleProductProvider'
import { getUrlParam } from 'consts'

const CartContext = createContext('config')

export const CartContextProvider = ({ children }) => {
  const history = useHistory()
  const { isMobileOrTablet } = useDeviceDetect()
  const [isLoaded, setLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [meta, setMeta] = useState({})
  const [orderModalOpen, setOrderModalOpen] = useState(false)
  const [payMethod, _setPayMethod] = useState('installment')
  const [bank, _setBank] = useState('tbc')
  const {
    product: { price: currentProductPrice },
  } = useProduct()
  const { auth } = useAuth()
  const { fetchOrders } = useOrders()
  const canSendOrder = useIfCanSendOrder()

  const fetchCart = () => {
    setLoading(true)
    CartService.getCart({
      ctclid: getUrlParam('ctclid'),
      gclid: getUrlParam('gclid'),
      fbclid: getUrlParam('fbclid'),
    })
      .then(c => {
        ReactDOM.unstable_batchedUpdates(() => {
          setLoading(false)
          if (!Array.isArray(c.products)) return
          setProducts(c.products)
          setMeta(c.meta)
          _setPayMethod(c.meta.pay_method || 'installment')
          _setBank(c.meta.bank)
        })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const addToCart = (sku, variants, showModal, redirect = true) => {
    return CartService.addToCart(sku, variants).then(() => {
      fetchCart()
      if (redirect) {
        history.push('/purchase')
      }
    })
  }

  const changeQuantity = (sku, quantity) => {
    CartService.updateQuantity(sku, quantity).then(() => {
      fetchCart()
    })
  }

  const deleteProduct = sku => {
    CartService.deleteProduct(sku).then(() => {
      fetchCart()
    })
  }

  const setOrderModal = (b = true) => {
    if (!isMobileOrTablet) {
      setOrderModalOpen(b)
    } else {
      history.push('/purchase')
    }
  }

  const setPayMethod = payMethod => {
    CartService.changePayMethod(payMethod).then(data => {
      _setPayMethod(payMethod)
    })
  }

  const chooseBank = bank => {
    CartService.changeBank(bank).then(() => {
      _setBank(bank)
    })
  }

  const placeOrder = () => {
    CartService.placeOrder().then(data => {
      incrementOrder()
      fetchCart()
      fetchOrders()
    })
  }

  const applyCardCouponCode = code => {
    CartService.applySaleCode(code).then(() => {
      fetchCart()
    })
  }

  useEffect(() => {
    fetchCart()
  }, [auth])

  useEffect(() => {
    if (products.length > 0) return

    setOrderModalOpen(false)
  }, [products])

  useEffect(() => {
    if (
      meta.price < 80 &&
      currentProductPrice < 80 &&
      payMethod === 'installment'
    ) {
      setPayMethod('cash')
    }
  }, [payMethod, meta.price, currentProductPrice])

  return (
    <CartContext.Provider
      value={{
        loading: !isLoaded,
        products: products,
        orderModalOpen: orderModalOpen,
        payMethod: payMethod,
        bank: bank,
        meta: meta,
        fetchCart: fetchCart,
        placeOrder: placeOrder,
        addToCart: addToCart,
        setOrderModal: setOrderModal,
        applyCardCouponCode: applyCardCouponCode,
        changeQuantity: changeQuantity,
        deleteProduct: deleteProduct,
        setPayMethod: setPayMethod,
        chooseBank: chooseBank,
        canSendOrder: canSendOrder,
        installmentAvialable: meta.price >= 80,
        isInstallment: payMethod === 'installment',
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

const useCart = () => useContext(CartContext)

export default useCart
